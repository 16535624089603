<template>
  <span class="profile-actions">
     <v-card-actions v-if="!isEdit" class="d-flex justify-space-between justify-sm-start pt-2 py-0 pb-1">
        <v-btn :ripple="false" text small color="primary" @click="$emit('change', !isEdit)">
          <v-icon small class="mr-1">mdi-pencil</v-icon>
          Editar
        </v-btn>
      </v-card-actions>
      <v-card-actions v-else class="d-flex justify-space-between justify-sm-start pt-2 py-0 pb-1">
        <v-btn :disabled="invalid" :ripple="false" text small color="primary" @click="_onSave">
          <v-icon small class="mr-1">mdi-content-save</v-icon>
          Salvar
        </v-btn>
        <v-btn :ripple="false" text color="grey darken-2" small class="ml-sm-4" @click="_onCancel">
          Cancelar
        </v-btn>
      </v-card-actions>
  </span>
</template>

<script>
export default {
  props: ['data','onSave','onCancel','isEdit','invalid'],
  model: {
    prop: 'isEdit',
    event: 'change'
  },
  methods: {
    _onSave() {
      this.onSave(this.data)
      this.$emit('change', false)
    },
    _onCancel() {
      this.$emit('change', false)
      this.onCancel && this.onCancel()
    }
  }
}
</script>

<style scoped>

</style>
