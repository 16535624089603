<template>
  <page class="profile" title="Perfil">
    <snackbar-message v-model="showSnackbar" :message="snackbarMessage" :status="snackbarStatus" />
    <v-card class="mt-1 pa-3 pb-2">
      <div class="my-2" v-if="users.status === 1">
        <span class="caption grey--text">atualizando...</span>
        <v-progress-linear indeterminate rounded/>
      </div>
      <ValidationObserver ref="observer" v-slot="{ invalid }">
        <form>
          <span v-if="!$vuetify.breakpoint.mobile">
            <profile-actions v-model="isEdit" :data="form" :invalid="invalid" :on-save="onSave" :on-cancel="onCancel"/>
          <v-divider></v-divider>
          </span>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="4">
                <label class="caption grey--text text--darken-1">Nome</label>
                <validation-provider
                    v-slot="{ errors }"
                    name="name"
                    rules="required"
                    v-if="isEdit"
                >
                  <v-text-field
                      v-model="form.name"
                      name="name"
                      required
                      :error-messages="errors"
                      class="ma-0 pa-0"
                  />
                </validation-provider>
                <div v-else class="grey--text text--darken-4">{{ auth.name | capitalize }}</div>
              </v-col>
              <v-col md="8" class="ma-0 pa-0"></v-col>
              <v-col cols="12" md="4">
                <label class="caption grey--text text--darken-1">E-mail</label>
                <validation-provider
                    v-slot="{ errors }"
                    name="email"
                    rules="required|email"
                    v-if="isEdit && isAdmin"
                >
                  <v-text-field
                      v-model="form.email"
                      type="email"
                      name="email"
                      required
                      :error-messages="errors"
                      @blur="$refs.observer.validate()"
                      class="ma-0 pa-0"
                  />
                </validation-provider>
                <div v-else class="grey--text text--darken-4">{{ auth.email }}</div>
              </v-col>
              <v-col md="8" class="ma-0 pa-0"></v-col>
              <v-col cols="12" md="4">
                <div v-if="isEdit && isAdmin">
                  <validation-provider
                      v-slot="{ errors }"
                      name="password"
                      rules="required|password:6"
                      v-if="isEditPassword"
                  >
                    <v-text-field
                        v-model="form.password"
                        id="new-password"
                        name="password"
                        label="Nova Senha"
                        :type="showPassword ? 'text' : 'password'"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="showPassword = !showPassword"
                        autocomplete="new-password"
                        required
                        :error-messages="errors"
                        class="ma-0 pa-0"
                    />
                  </validation-provider>
                  <div v-if="isEditPassword && form.password" class="caption grey--text text--darken-1 mt-2 mb-3">
                    após clicar em 'salvar' você será redirecionado para fazer o login novamente.
                  </div>
                  <v-btn v-if="!isEditPassword" color="primary" x-small @click="onNewPassword">
                    <v-icon x-small class="mr-1">mdi-key</v-icon>
                    alterar senha
                  </v-btn>
                  <v-btn class="mt-2" v-if="isEditPassword" x-small @click="onCancelEditPassword">
                    cancelar alterar senha
                  </v-btn>
                </div>
                <div v-else>
                  <label class="caption grey--text text--darken-1">Senha</label>
                  <div>
                    <v-icon color="grey darken-3" v-for="i in [1,2,3,4,5]" :key="i">mdi-circle-medium</v-icon>
                  </div>
                </div>
              </v-col>
              <v-col md="8" class="ma-0 pa-0"></v-col>
              <v-col cols="12" md="4">
                <v-card v-if="isEdit && !isAdmin" color="grey lighten-4" elevation="0" class="my-3">
                  <v-card-text class="caption pa-1 px-3">
                    para alterar o e-mail ou a senha solicite com o administrador do sistema.
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <span v-if="$vuetify.breakpoint.mobile">
            <v-divider></v-divider>
            <profile-actions v-model="isEdit" :data="form" :invalid="invalid" :on-save="onSave" :on-cancel="onCancel"/>
          </span>
        </form>
      </ValidationObserver>
    </v-card>
  </page>
</template>

<script>
import {required, min, email} from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import Page from "@/components/containers/page";
import ProfileActions from "@/components/profile/profile-actions";
import SnackbarMessage from "@/components/snackbars/snackbar-message";

extend("email", {
  ...email,
  message: "e-mail inválido",
});

extend("password", {
  ...min,
  message: 'senha deve ter no mínimo 6 dígitos',
});

extend("required", {
  ...required,
  message: 'obrigatório',
});

setInteractionMode("eager");

export default {
  components: {
    SnackbarMessage,
    ProfileActions,
    Page,
    ValidationObserver,
    ValidationProvider
  },
  data: () => ({
    isEdit: false,
    isEditPassword: false,
    showPassword: false,
    showSnackbar: false,
    snackbarStatus: 'error',
    snackbarMessage: '',
    form: {
      name: '',
      email: '',
      password: ''
    }
  }),
  computed: {
    auth() {
      return this.$store.state.auth.data;
    },
    users() {
      return this.$store.state.users;
    },
    isAdmin() {
      return this.$store.getters["auth/isAdmin"]
    }
  },
  methods: {
    onSave(data) {
      if(this.$refs.observer.validate()) {

        if(this.isAdmin) {

          if(this.isEditPassword) {
            data = {
              id: this.auth.id,
              name: data.name,
              email: data.email,
              password: data.password
            }
          } else {
            data = {
              id: this.auth.id,
              name: data.name,
              email: data.email
            }
          }

          this.$store.dispatch('users/update', data)
              .then((id) => {
                if(id !== this.auth.id) {
                  this.snackbarMessage = 'Erro após atualizar os dados, tente novamente.'
                  this.snackbarStatus = 'error'
                  this.showSnackbar = true
                  return null;
                }

                const authData = Object.assign({}, this.auth)

                authData.name = this.form.name
                authData.email = this.form.email

                this.$store.commit('auth/setData', authData)

                if(this.isEditPassword) {
                  setTimeout(() => {
                    this.snackbarMessage = 'Senha alterada com sucesso você deverá fazer o login, redirecionando...'
                    this.snackbarStatus = 'success'
                    this.showSnackbar = true
                    setTimeout(() => {
                      this.$router.push({name: 'Login'})
                      this.$store.dispatch('auth/revokeAthentication')
                    }, 4000)
                  }, 4000)
                }
              })
              .catch(err => {
                if([401,403].includes(err.status)) {
                  setTimeout(() => {
                    this.$router.push({name: 'Login'})
                    this.$store.dispatch('auth/revokeAthentication')
                  }, 2000)
                }
              })
        } else {
          this.$store.dispatch('users/updateName', {name: data.name, email: data.email})
              .then((id) => {
                if(id !== this.auth.id) {
                  this.snackbarMessage = 'Erro após atualizar os dados, tente novamente.'
                  this.snackbarStatus = 'error'
                  this.showSnackbar = true
                  return null;
                }

                const authData = Object.assign({}, this.auth)

                authData.name = this.form.name

                this.$store.commit('auth/setData', authData)
              })
              .catch(err => {
                if([401,403].includes(err.status)) {
                  setTimeout(() => {
                    this.$router.push({name: 'Login'})
                    this.$store.dispatch('auth/revokeAthentication')
                  }, 2000)
                }
              })
        }
      }
    },
    onCancel() {
      this.onCancelEditPassword()
      this.$refs.observer.reset()
    },
    onNewPassword() {
      this.isEditPassword = true
      setTimeout(() => document.getElementById('new-password').focus(), 200)
    },
    onCancelEditPassword() {
      this.isEditPassword = false
      this.form.password = ''
    }
  },
  watch: {
    isEdit(nextValue) {
      if (nextValue) {
        this.form.email = this.auth.email
        this.form.name = this.auth.name
      }
    },
    'users.status'(next,prev) {
      if (prev === 1 && next === -1 && ['update','updateName'].includes(this.users.operation)) {
        this.snackbarMessage = this.users.message
        this.snackbarStatus = 'error'
        this.showSnackbar = true
      } else if(prev === 1 && next === 0 && ['update','updateName'].includes(this.users.operation)) {
        this.snackbarMessage = this.users.message
        this.snackbarStatus = 'success'
        this.showSnackbar = true
      }
    }
  }
}
</script>

<style scoped>

</style>
